import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { UseScrollPositionExample } from './use-scroll-position.example.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "usescrollposition"
    }}>{`useScrollPosition`}</h1>
    <p>{`Keep track of window scroll position while the user scrolls. Optionally you can
pass in a throttle wrapper to throttle the update calls (e.g. using
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/lodash.throttle"
      }}>{`lodash.throttle`}</a>{` or
`}<inlineCode parentName="p">{`window.requestAnimationFrame`}</inlineCode>{`).`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import { useScrollPosition } from '@fransvilhelm/hooks';

const FadeInOnPosition = ({ breakpoint, children }) => {
  const { y } = useScrollPosition((fn) => () =>
    window.requestAnimationFrame(fn),
  );
  const fadeIn = y >= breakpoint;

  return (
    <Animation type="fadeIn" enable={fadeIn}>
      {children}
    </Animation>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<UseScrollPositionExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      UseScrollPositionExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UseScrollPositionExample mdxType="UseScrollPositionExample" />
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`useScrollPosition`}</inlineCode>{` accepts one optional parameter – a throttling function`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`throttler`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ThrottleWrapper`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The throttle function should accept a callback as initial argument and the return another function that when called will throttle the update`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <p><inlineCode parentName="p">{`useScrollPosition`}</inlineCode>{` returns an object with the current coordinates for the
scroll position.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The current coordinate on the x-axis (`}<inlineCode parentName="td">{`window.pageXOffset`}</inlineCode>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`y`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The current coordinate on the y-axis (`}<inlineCode parentName="td">{`window.pageYOffset`}</inlineCode>{`)`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      